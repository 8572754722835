import React, { useState } from "react";
import Form from "react-jsonschema-form";
import { JSONSchema6 } from "json-schema";

import SearchSchema from "./DocumentSearchSchema.json";
import API, { ApiResponse } from "../../services/api";
import { useOktaAuth } from "@okta/okta-react";
import Utils from "../../services/utils";
import DocumentsTable, { DocumentData } from "./DocumentsTable";

export interface SearchCriteria {
  policyNumber: string;
  partnerId: string;
}

const Documents = () => {
  const [searchForm, setSearchForm] = useState<SearchCriteria>({
    policyNumber: "",
    partnerId: "",
  });
  const { authState } = useOktaAuth();
  const [searchResult, setSearchResult] = useState<Array<DocumentData>>([]);
  const [showResult, setShowResult] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [paramSearchProcessed, setParamSearchProcessed] = useState(false);
  const [message, setMessage] = useState("");

  const handleSearch = async (searchParams: SearchCriteria) => {
    setMessage("");
    setLoadingState(true);
    const loadSearchResults = async (): Promise<ApiResponse<any>> => {
      return await API.get<Array<DocumentData>>(`documents?policyNumber=${searchParams.policyNumber}`, searchParams.partnerId, authState);
    };
    const searchResults = loadSearchResults();
    const finalResult = await searchResults;
    if (finalResult.success) {
      setSearchResult(finalResult.data.documentDataList);
      setMessage("");
      setShowResult(true);
    } else {
      setMessage(finalResult.message);
      setShowResult(false);
    }
    setLoadingState(false);
  };

  const handleFormSearch = async () => {
    handleSearch(searchForm);
  };

  const handleFindChange = (newData: SearchCriteria) => {
    setSearchForm(newData);
  };

  const handleReset = () => {
    setSearchForm({
      policyNumber: "",
      partnerId: "",
    });
    setLoadingState(false);
    setShowResult(false);
  };

  if (!paramSearchProcessed) {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("policyNumber") && queryParams.get("partnerId")) {
        const paserParams = (key: string) => {
          return queryParams.get(key) || "";
        };
        const params: SearchCriteria = {
          policyNumber: paserParams("policyNumber"),
          partnerId: paserParams("partnerId"),
        };
        setSearchForm(params);
        handleSearch(params);
        setParamSearchProcessed(true);
        setShowResult(true);
      }
    }
  }

  return (
    <div className="nav-form px-3">
      {!showResult && (
        <div className="horiz-split">
          <Form
            schema={SearchSchema as JSONSchema6}
            validate={Utils.validate}
            formData={searchForm}
            onChange={(data) => {
              handleFindChange(data.formData);
            }}
            onSubmit={handleFormSearch}
            showErrorList={true}
          >
            <div className="button-box">
              {!loadingState && (
                <button data-testid="search-button" type="submit">
                  Search
                </button>
              )}
              {loadingState && (
                <button data-testid="loading-icon-form" disabled>
                  Loading...
                </button>
              )}
            </div>
            {message && (
              <div data-testid="search-message" className="message-box">
                {message}
              </div>
            )}
          </Form>
        </div>
      )}
      {showResult && (
        <div className="result-container">
          {loadingState && (
            <div className="button-box">
              <button data-testid="loading-icon-params">Loading...</button>
            </div>
          )}
          {!loadingState && (
            <>
              <div className="new-search-box">
                <div className="button-box">
                  <button data-testid="new-search-button" type="submit" onClick={handleReset}>
                    New Search
                  </button>
                </div>
              </div>
              <DocumentsTable searchList={searchResult} policyNumber={searchForm.policyNumber} partnerId={searchForm.partnerId} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Documents;
