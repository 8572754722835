import { FormValidation } from "react-jsonschema-form";
import { ApiResponse } from "./api";

// Class for holding utility functions
class Utils {
  static capitalize = (str: string) => {
    return str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };

  static getTermValue = (value: string, valueType: string) => {
    var currencyFormatter = require("currency-formatter");
    if (valueType === "Currency") {
      return currencyFormatter.format(value, { code: "USD" });
    } else if (valueType === "Percentage") {
      return value + "%";
    }
    return value;
  };

  static matchEnum = (value: any, checkEnum: Object): boolean => {
    return Object.values(checkEnum).includes(value);
  };

  static getFileUrl = (response: ApiResponse<any>): string | undefined => {
    const byteCharacters = window.atob(response.data.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: response.data.mimeType });
    return URL.createObjectURL(file);
  };

  static openUrl = (url: string | undefined) => {
    console.log(url);
    if (url) {
      window.open(url);
    }
  };

  static serialize = (obj: any) => {
    let str = [];
    console.log(obj);
    // if (obj[p] !== "" && obj[p] !== undefined) {
    for (var p in Object.keys(obj)) str.push(encodeURIComponent(`${p}=${obj[p]}`));
    // }
    return str.join("&");
  };

  static validate = (formData: any, errors: FormValidation) => {
    if (!/^[a-zA-Z\s-]+$/.test(formData.firstName) && formData.firstName !== undefined) {
      errors.firstName.addError("Only alphabet characters, spaces, and hyphens allowed");
    }
    if (!/^[a-zA-Z\s-]+$/.test(formData.lastName) && formData.lastName !== undefined) {
      errors.lastName.addError("Only alphabet characters, spaces, and hyphens allowed");
    }
    if (!/^(?:[A-Z]{0}|[A-Z]{2}|)$/.test(formData.state) && formData.state !== undefined) {
      errors.state.addError("State must consist of 2 uppercase letters");
    }

    if (!/^(?:[0-9]{7}|[0-9]{10}|)$/.test(formData.phoneNumber) && formData.phoneNumber !== undefined) {
      errors.phoneNumber.addError("Phone Number requires 7-10 digits - no other characters allowed");
    }

    if (!/^(?:[0-9]{5}|[0-9]{5}|)$/.test(formData.zipCode) && formData.zipCode !== undefined) {
      errors.zipCode.addError("Zip code requires 5 digits");
    }
    if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(formData.dateOfBirth) && formData.dateOfBirth !== undefined) {
      errors.dateOfBirth.addError("Invalid Date Format (MM-DD-YYYY)");
    }
    if (!/^\d{2}[/]\d{2}[/]\d{4}$/.test(formData.dateOfLoss) && formData.dateOfLoss !== undefined) {
      errors.dateOfLoss.addError("Invalid Date Format (MM/DD/YYYY)");
    }

    return errors;
  };
}

export default Utils;
