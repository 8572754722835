import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";

import OktaConfig from "./services/oktaConfig";

import Navbar from "./controls/NavBar/NavBar";
import Documents from "./content/Document/Documents";
import LogOut from "./controls/NavBar/LogOut";

const AppWithRouterAccess = () => {
  return (
    <Security oktaAuth={OktaConfig}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/documents" />
        </Route>
        <SecureRoute path="/documents" component={Documents}>
          <Navbar />
          <Documents />
        </SecureRoute>
        <Route path="/callback" component={LoginCallback} />
        <Route path="/logout">
          <LogOut />
        </Route>
      </Switch>
    </Security>
  );
};
export default AppWithRouterAccess;
