import axios from "axios";
import https from "https";
import uuid from "react-uuid";
import { AuthState } from "@okta/okta-auth-js";

const httpsAgent = new https.Agent({
  minVersion: "TLSv1.2",
});

/**
 * Type definition for API responses.
 * All API requests will return success or failure.
 * API requests should always return a message on failure.
 * API requests which retrieve data will return that data in a data object.
 */
export type ApiResponse<T> = {
  success: boolean;
  message: string;
  data?: T;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});
api.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "afi-api-key": process.env.REACT_APP_API_KEY,
  "afi-appname": process.env.REACT_APP_AFI_APPNAME,
  "afe-source-id": process.env.REACT_APP_AFE_SOURCE_ID,
  "afe-request-id": uuid(),
};

/**
 * Handles error responses.
 *
 * @param {*} e
 */

const handleError = (e: { response: { data: { status: { code: string; reason: string } } }; message: any }): ApiResponse<any> => {
  console.error(e);
  console.error(e.message);
  console.error(e.response.data.status.reason);
  const success = false;
  const message = e.response && e.response.data && e.response.data.status.reason ? e.response.data.status.reason : e.message;
  return { success, message };
};

class API {
  static all = axios.all;
  static spread = axios.spread;

  /**
   * Submits a GET request to the specified endpoint.
   *
   * @param {*} endpoint
   */
  static async get<T>(endpoint: string, partnerId: string, authState: AuthState): Promise<ApiResponse<T>> {
    return await api
      .get(endpoint, {
        withCredentials: true,
        httpsAgent,
        headers: {
          "afe-partner-id": partnerId,
          Authorization: `Bearer ${authState && authState.accessToken ? authState.accessToken.accessToken : ""}`,
        },
      })
      .then((result) => {
        return { success: true, message: "Success", data: result.data };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  static async getBlob(endpoint: string, partnerId: string, authState: AuthState): Promise<ApiResponse<Blob>> {
    return await api
      .get(endpoint, {
        responseType: "json",
        withCredentials: true,
        httpsAgent,
        headers: {
          "afe-partner-id": partnerId,
          Authorization: `Bearer ${authState && authState.accessToken ? authState.accessToken.accessToken : ""}`,
        },
      })
      .then((result) => {
        return { success: true, message: "Success", data: result.data };
      })
      .catch((e) => {
        return handleError(e);
      });
  }
  /**
   * Submits a POST request to the specified endpoint with the supplied data.
   *
   * @param {*} endpoint
   * @param {*} data
   */
  static async post(endpoint: string, data: Object, authState: AuthState): Promise<ApiResponse<any>> {
    return await api
      .post(endpoint, data, { withCredentials: true, httpsAgent, headers: { Authorization: `Bearer ${authState && authState.accessToken ? authState.accessToken.accessToken : ""}` } })
      .then((result) => {
        const data = result.data;
        const success = result.status === 200 ? true : false;
        const message = data && data.message ? data.message : "";
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  /**
   * Submits a PUT request to the specified endpoint with the supplied data.
   *
   * @param {*} endpoint
   * @param {*} data
   */
  static async put(endpoint: string, data: Object, authState: AuthState): Promise<ApiResponse<any>> {
    return await api
      .post(endpoint, data, { withCredentials: true, httpsAgent, headers: { Authorization: `Bearer ${authState && authState.accessToken ? authState.accessToken.accessToken : ""}` } })
      .then((result) => {
        const data = result.data;
        const success = data.success;
        const message = data.message;
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  /**
   * Submits a DELETE request to the specified endpoint.
   * NOTE: This cannot be named 'delete' because it is a reserved word in JavaScript.
   *
   * @param {*} endpoint
   */
  static async delete(endpoint: string, authState: AuthState): Promise<ApiResponse<any>> {
    return await api
      .delete(endpoint, { withCredentials: true, httpsAgent, headers: { Authorization: `Bearer ${authState && authState.accessToken ? authState.accessToken.accessToken : ""}` } })
      .then((result) => {
        const data = result.data;
        const success = data.success;
        const message = data.message;
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }
}

export default API;
