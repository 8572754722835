import React from "react";
import LogOutButton from "./LogOutButton";

import logo from "../../assets/images/Homesite_Logo.png";

function NavBar() {
  return (
    <div data-testid="af-logobar" className="af-logobar px-3">
      <div className="col d-inline-flex align-items-center w-100 my-2 px-0">
        <div className="col d-inline-flex justify-content-left align-items-start pl-0">
          <img src={logo} className="af-logo" alt="Homesite | Powered by American Family Insurance" />
          <span className="ml-2 align-self-center">
            <h4 className="mb-0">Policy Document</h4>
          </span>
        </div>
        <LogOutButton />
      </div>
    </div>
  );
}

export default NavBar;
