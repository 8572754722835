import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/styles/App.scss";
import AppWithRouterAccess from "./AppWithRouterAccess";

function App() {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
}
export default App;
