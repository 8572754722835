import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

import API, { ApiResponse } from "../../services/api";
import Utils from "../../services/utils";

type Props = {
  ucmId: string;
  policyNumber: string;
  partnerId: string;
};

const DocumentViewButton = (props: Props) => {
  const { authState } = useOktaAuth();
  const [loadingState, setLoadingState] = useState("View");

  const viewDocument = (ucmId: any) => {
    const loadDoc = async (): Promise<ApiResponse<any>> => {
      const url = `${process.env.REACT_APP_API_URL}documents/${ucmId}?policyNumber=${props.policyNumber}`;
      return await API.getBlob(url, props.partnerId, authState);
    };

    const loadData = async () => {
      const documentResult = loadDoc();
      const finalResult = await documentResult;
      const fileUrl = Utils.getFileUrl(finalResult);
      Utils.openUrl(fileUrl);
      setLoadingState("View");
    };

    loadData();
    setLoadingState("Loading...");
  };

  return (
    <button name="view-document" onClick={() => viewDocument(props.ucmId)}>
      {loadingState}
    </button>
  );
};

export default DocumentViewButton;
