import { OktaAuth } from "@okta/okta-auth-js";

// AmFam Enterprise Account
const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/callback",
  postLogoutRedirectUri: window.location.origin + "/logout",
};

export default new OktaAuth(oktaAuthConfig);
