import React from "react";

function LogOut() {
  return (
    <div>
      <h1 className="text-center">Successful Log Out</h1>
    </div>
  );
}

export default LogOut;
