import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";

function LogOutButton() {
  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const logout = async () => {
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut();
  };
  return (
    <div className="col d-inline-flex justify-content-end ml-2 pr-0">
      {userInfo ? <span className="d-none d-md-inline align-self-center mr-2">{userInfo.email}</span> : null}
      <Link to="/logout">
        <button data-testid="logout" onClick={logout}>
          Log Out
        </button>
      </Link>
    </div>
  );
}

export default LogOutButton;
